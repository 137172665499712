'use client'
import { Link, usePathname } from '@/navigation'

export default function CCP_HospitalTopBar() {
  const pathname = usePathname()
  const hospitalList = [
    'Bangkok General Hospital',
    'Bangkok International Hospital',
    'Bangkok Heart Hospital',
    'Wattanosoth Cancer Hospital',
  ]
  const allowedPaths = ['/heart-hospital', '/cancer-hospital', '/bangkok-heart']
  if (!allowedPaths.includes(pathname)) return null
  return (
    <div className="tw-top-0 tw-w-full tw-bg-bgh-blue-fade tw-flex tw-items-center tw-justify-between tw-border-y tw-border-bgh-blue-alpha">
      {/* Left */}
      <div className="tw-px-8 tw-py-2 tw-flex tw-items-center tw-space-x-4">
        <Link href="/">
          <h6 className="tw-text-primary/50 tw-text-sm hover:tw-text-bgh-blue tw-duration-200">
            Bangkok Hospital
          </h6>
        </Link>
        <i className="fas fa-caret-right tw-text-primary/20 tw-text-xs"></i>
        <h6 className="tw-text-primary/50 tw-text-sm !tw-font-light">
          Bangkok Heart Hospital
        </h6>
      </div>
      {/* Right */}
      <div className="tw-relative tw-h-full tw-py-2 tw-cursor-pointer tw-flex hover:tw-bg-bgh-blue-alpha tw-group tw-duration-200 tw-items-center tw-space-x-4 tw-px-8 tw-border-l tw-border-bgh-blue-alpha">
        <h6 className="tw-text-bgh-blue-bright !tw-font-medium tw-text-sm">
          Change Hospital
        </h6>
        <i className="fal fa-chevron-down tw-text-bgh-blue-bright tw-text-xs group-hover:tw-translate-y-1 tw-duration-200"></i>
        {/* List */}
        <div className="tw-z-50 tw-opacity-0 tw--translate-y-4 tw-pointer-events-none group-hover:tw-opacity-100 group-hover:tw--translate-y-0 group-hover:tw-pointer-events-auto tw-duration-200 tw-absolute tw-shadow-main-blue tw-rounded-b-sm tw-w-full tw-top-[100%] tw-py-2 tw-right-0 tw-bg-white">
          {hospitalList.map((hospital, index) => (
            <h6
              key={index}
              className="tw-px-4 tw-py-2 tw-text-sm !tw-font-medium tw-text-bgh-gray-primary hover:tw-bg-bgh-blue-fade hover:tw-text-bgh-blue tw-duration-200"
            >
              {hospital}
            </h6>
          ))}
        </div>
      </div>
    </div>
  )
}
