import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/var/www/bhq-nextjs/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/src/components/main/b-point/snackbar/SnackbarPointWrapper.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/src/components/main/button/ToTop.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/src/components/main/dialog/DialogConfirmation.jsx");
;
import(/* webpackMode: "eager" */ "/var/www/bhq-nextjs/src/components/main/element/ColorBar.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/src/components/main/element/HospitalTopBar.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/src/components/main/footer/FooterContact.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/src/components/main/footer/FooterMenu.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/src/components/main/header/AnnouncementWrapper.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/src/components/main/header/CTABar.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/src/components/main/header/HeaderMenu.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/src/components/main/header/PhoneCTA.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/src/components/main/header/SiteLogo.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/src/components/main/modal/ButtonModal.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/src/components/main/modal/SelectCenterModal.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/src/components/main/modal/SimpleModal.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/src/components/main/modal/SuccessModal.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/src/components/main/popup/HomePopUp.jsx");
;
import(/* webpackMode: "eager" */ "/var/www/bhq-nextjs/src/styles/main/main.css");
