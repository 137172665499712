export const getMeta = async (locale = 'th', hospital = 'bgh') => {
  // if locale contains sw.js, return
  if (locale.includes('.')) {
    return null
  }
  const metaBaseURL = `${process.env.NEXT_PUBLIC_FRONTEND_URL}/backend/wp-content/meta-json`
  let url = `${metaBaseURL}/${locale}-${hospital}.json`
  // process.env.NEXT_PUBLIC_FRONTEND_URL doesn't contain `www`, add cache busting
  if (!url.includes('www')) {
    url += `?v=${new Date().getTime()}`
  }

  try {
    const res = await fetch(url)
    const data = await res.json()
    return data
  } catch (error) {
    console.error('Error fetching meta:', error)
    return null
  }
}
