import useTextDirection from '@/hooks/useTextDirection'

const PrimaryButton = ({
  label,
  icon,
  addedClass = '',
  isSmall = false,
  type = 'button',
  isLoading = false,
  isReversed = false,
  color = '',
}) => {
  const direction = useTextDirection()
  if (!label) return
  return (
    <button
      style={color ? { backgroundColor: color } : {}}
      type={type}
      disabled={isLoading}
      className={`${addedClass} ${
        isSmall ? '!tw-py-3 !px-8 !tw-min-w-0' : ''
      } ${isReversed ? 'tw-flex-row-reverse tw-space-x-reverse' : ''} sm:tw-min-w-44 tw-min-w-32 tw-rounded-lg md:tw-py-4 sm:tw-px-7 tw-px-4 tw-py-3 hover:tw-bg-primary tw-duration-200 tw-relative tw-flex tw-items-center tw-justify-center tw-space-x-3 tw-overflow-hidden button-animate ${isLoading ? 'tw-cursor-not-allowed tw-bg-bgh-blue/75' : 'tw-cursor-pointer tw-bg-bgh-blue'}`}
      dir={direction}
    >
      {isLoading ? (
        <h6 className=" tw-min-h-[24px]">
          <i className="fas fa-fw fa-spinner-third tw-animate-spin tw-text-white" />
        </h6>
      ) : (
        <>
          {icon ? <i className={`${icon} tw-text-white tw-relative`} /> : null}
          <span
            className="tw-text-white tw-capitalize tw-font-bold"
            dangerouslySetInnerHTML={{ __html: label }}
          />
        </>
      )}
    </button>
  )
}

export default PrimaryButton
