export default function shouldShowCtaHideColorBar(pathname) {
  const specialPath = [
    '/campaign',
    '/healthcare-intelligence',
    '/search',
    '/heart-hospital',
    '/bangkok-heart',
    '/cancer-hospital',
  ]
  const pathnameContainsSpecialPath = specialPath.some((path) =>
    pathname.includes(path)
  )
  const shouldShowCtaHideColorBar =
    !pathnameContainsSpecialPath && pathname !== '/'
  return shouldShowCtaHideColorBar
}
