'use client'

import TouchRipple from '@/components/main/element/TouchRipple'
import { Link, usePathname } from '@/navigation'
import useMeta from '@/store/meta'
import { Skeleton } from '@mui/material'
import { useTranslations } from 'next-intl'
import { useEffect, useState } from 'react'
import LanguageSwitcher from './LanguageSwitcher'
import MobileCTA from './MobileCTA '
import CCP_MobileExpandMenu from './MobileExpandMenu'
import SearchButton from './SearchButton'
import CCP_UserMenu from './UserMenu'

export default function CCP_HeaderMenu() {
  const __t = useTranslations()
  const [disablePointer, setDisablePointer] = useState(false)
  const pathname = usePathname()
  const mainMenu = useMeta((state) => state?.metaInfo?.main_menu) || []
  const metaIsLoading = useMeta((state) => state?.metaIsLoading)

  // toggle dropdown by reset pointer events
  const toggleMenu = () => {
    setDisablePointer(true)
    const timer = setTimeout(() => {
      setDisablePointer(false)
    }, 100)
    return () => clearTimeout(timer)
  }

  const clickLink = (newPath) => () => {
    //if going to the same path, close menu
    if (pathname.includes(newPath)) {
      toggleMenu()
    }
  }

  // Watch for route change, then reset  menu
  useEffect(() => {
    toggleMenu()
  }, [pathname])

  return (
    <>
      {/* Desktop */}
      <div className="md:tw-flex tw-hidden tw-items-center tw-py-2 xl:tw-py-4">
        <ul className="tw-flex tw-items-center">
          {metaIsLoading &&
            [1, 2, 3, 4].map((i) => (
              <li key={`header-menu-${i}`} className="tw-mr-5">
                <Skeleton variant="text" width={120} height={30} />
              </li>
            ))}

          {!metaIsLoading &&
            mainMenu.map((menu, i) => (
              <li
                key={`header-menu-${i}`}
                className="tw-flex tw-relative tw-items-center tw-space-x-2 menu-button tw-group"
              >
                {/* Main Menu */}
                <Link
                  aria-label={`${__t('go-to')} ${__t(menu.title_slug)}`}
                  prefetch={false}
                  className="menu-group group-hover:tw-text-bgh-blue  after:tw-absolute after:tw-left-0 after:tw-top-0 after:tw-h-full after:tw-w-full"
                  href={menu.path ? menu.path : ''}
                >
                  <span className="text-button tw-text-primary tw-duration-200 !tw-leading-tight min-[1140px]:!tw-text-[1.15rem] !tw-text-[0.95rem]">
                    {__t(menu.title_slug)}
                  </span>
                </Link>
                {/* Submenu */}
                {menu.items?.length ? (
                  <>
                    <i className="far fa-chevron-down tw-text-sm tw-text-bgh-blue group-hover:tw-text-bgh-blue/50  group-hover:ltr:tw-rotate-90 group-hover:rtl:-tw-rotate-90 group-hover:tw-font-bold tw-duration-300 " />

                    <div
                      className={`
                tw-opacity-0 tw-pointer-events-none -tw-translate-y-4 group-hover:tw-opacity-100 group-hover:tw-pointer-events-auto group-hover:tw-translate-y-[-1px] tw-duration-200 group-hover:after:tw-translate-y-0 after:tw-translate-y-[12px] after:tw-duration-200 after:tw-delay-200 tw-absolute tw-top-[calc(100%+1px)] tw-min-w-[333px] tw-py-2 tw-bg-white tw-rounded-md tw-shadow-submenu after:tw-absolute after:tw-border-b-[12px] after:tw-border-x-[12px] after:tw-border-t-0 after:tw-border-b-white after:tw-border-x-transparent after:tw-top-[-12px] 
                ${
                  i + 1 > mainMenu.length / 2
                    ? 'ltr:tw-right-[-50px] rtl:tw-left-[-50px] ltr:after:tw-right-[100px] rtl:after:tw-left-[100px]'
                    : 'ltr:tw-left-[-50px] rtl:tw-right-[-50px] ltr:after:tw-left-[100px] rtl:after:tw-right-[100px]'
                } 
                ${disablePointer ? '!tw-pointer-events-none' : ''}`}
                    >
                      <ul>
                        {menu.items.map((submenu, j) =>
                          submenu.icon ? (
                            <li
                              key={`submenu-wrapper-${i}-${j}`}
                              onClick={clickLink(submenu.path)}
                            >
                              <TouchRipple
                                ariaLabel={`${__t('go-to')} ${__t(submenu.title_slug)}`}
                                prefetch={false}
                                key={`header-submenu-${i}-${j}`}
                                href={submenu.path}
                                className="hover:tw-bg-bgh-blue-alpha/50 tw-flex tw-items-center tw-space-x-3  tw-px-4 tw-py-3 tw-duration-200 tw-group"
                              >
                                {submenu.icon ? (
                                  <div
                                    className={`tw-w-8 tw-h-8 tw-rounded-full tw-flex tw-items-center tw-justify-center ${
                                      submenu.isSolid
                                        ? 'tw-bg-primary'
                                        : 'tw-bg-bgh-blue-alpha'
                                    }`}
                                  >
                                    <i
                                      className={`far fa-${submenu.icon} ${
                                        submenu.isSolid
                                          ? 'tw-text-white'
                                          : 'tw-text-primary'
                                      } `}
                                    />
                                  </div>
                                ) : null}
                                <span className="text-button tw-text-primary">
                                  {__t(submenu.title_slug)}
                                </span>
                              </TouchRipple>
                              {/* SubSubmenu */}
                              {submenu.subitems?.length ? (
                                <ul className="tw-grid tw-grid-cols-2 tw-pl-[58px] tw-pr-4 tw-pb-4">
                                  {submenu.subitems.map((subitem, k) => (
                                    <li
                                      key={`header-subitem-${i}-${j}-${k}`}
                                      className="tw-flex"
                                    >
                                      <Link
                                        aria-label={`${__t('go-to')} ${__t(subitem.title_slug)}`}
                                        prefetch={false}
                                        href={subitem.path}
                                        className="tw-text-bgh-gray-primary hover:tw-text-black tw-duration-200 tw-font-bold tw-w-full tw-p-1 tw-rounded"
                                      >
                                        {__t(subitem.title_slug)}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              ) : null}
                            </li>
                          ) : null
                        )}
                      </ul>
                    </div>
                  </>
                ) : null}
              </li>
            ))}
        </ul>
        <LanguageSwitcher />
        <SearchButton />
        <CCP_UserMenu />
      </div>
      {/* Mobile */}
      <div className="md:tw-hidden sm:tw-w-1/2 tw-w-full tw-grid tw-grid-cols-4  tw-text-primary tw-max-w-fit">
        <MobileCTA />
        <div className="tw-flex tw-items-center tw-justify-center">
          <CCP_UserMenu />
        </div>
        <CCP_MobileExpandMenu mobileMenu={mainMenu} />
      </div>
    </>
  )
}
