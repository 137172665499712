'use client'

import { Link } from '@/navigation'
import useMeta from '@/store/meta'
import getDirFromStr from '@/utils/getDirFromStr'
import removeHTMLTag from '@/utils/removeHTMLTag'
import { useBreakpoint } from '@/utils/responsive'
import Collapse from '@mui/material/Collapse'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import { useState } from 'react'

const FooterMenu = () => {
  const { metaInfo } = useMeta()
  const __t = useTranslations()
  const isSm = useBreakpoint('sm')
  const [activeMenus, setActiveMenus] = useState([])
  const clickMenu = (i) => (event) => {
    event.preventDefault() // Prevent the default behavior
    setActiveMenus(
      activeMenus.includes(i)
        ? activeMenus.filter((menu) => menu !== i)
        : [...activeMenus, i]
    )
  }
  let contactMenu = [
    {
      icon: 'fas fa-map-marker-alt',
      text: metaInfo?.address,
      link: metaInfo?.google_maps_link,
    },
    {
      icon: 'fas fa-phone-alt',
      text: metaInfo?.phone_number,
    },
    {
      link: `mailto:${metaInfo?.email}`,
      icon: 'fas fa-envelope',
      text: metaInfo?.email,
    },
  ]
  // special_contact_channels + override_special_contact_channels
  const channels =
    metaInfo?.override_special_contact_channels ||
    metaInfo?.special_contact_channels
  if (channels) {
    contactMenu = [
      ...contactMenu,
      ...channels.map((channel) => ({
        link: channel.url,
        icon: `fab fa-${channel.fa_icon.icon}`,
        text: channel.text_display,
        custom_icon: channel.custom_icon,
      })),
    ]
  }

  return (
    <div className="tw-bg-bgh-gray-dark sm:tw-py-8">
      {/* Container */}
      <nav className="tw-container">
        {/* Grid */}
        <div className="tw-grid md:tw-grid-cols-5 sm:tw-grid-cols-3 tw-grid-cols-1 sm:tw-gap-8 max-sm:tw-divide-y tw-divide-bgh-blue-alpha/20">
          {metaInfo?.main_menu?.map((menu, i) => (
            <div key={`footer-menu-${i}`}>
              {/* Main Menu */}
              <button
                aria-label={`${__t('go-to')} ${__t(menu.title_slug)}`}
                onClick={clickMenu(i)}
                className={`tw-text-white/95 tw-inline-flex tw-items-center tw-space-x-2 tw-group/menu max-sm:tw-py-4 ${
                  menu.slug ? '' : 'sm:tw-pointer-events-none'
                } ${
                  menu.alwaysExpanded
                    ? ''
                    : 'max-sm:tw-w-full max-sm:tw-flex max-sm:tw-items-center max-sm:tw-justify-between'
                }`}
              >
                {/* Main icon + Always expanded icon */}
                <div className="tw-flex tw-items-center tw-space-x-2">
                  {menu.icon ? (
                    <i className={`${menu.icon} tw-text-sm`} />
                  ) : null}
                  <span className="tw-text-white/95 tw-font-bold tw-duration-200">
                    {__t(menu.title_slug)}
                  </span>
                </div>
                {/* Expanded icon */}
                {menu.alwaysExpanded ? (
                  ''
                ) : (
                  <i
                    className={`sm:!tw-hidden fas ${
                      activeMenus.includes(i) ? 'fa-minus' : 'fa-plus'
                    }`}
                  />
                )}
              </button>
              {/* Child Menu */}
              <Collapse
                in={activeMenus.includes(i) || menu.alwaysExpanded || isSm}
              >
                <ul className="tw-flex tw-flex-col tw-items-start tw-space-y-2 sm:tw-mt-4 tw-mb-4 max-sm:tw-flex">
                  {menu?.items?.length > 0
                    ? menu?.items?.map((item, i) => (
                        <li
                          key={`footer-sub-menu-${i}`}
                          className="tw-flex tw-items-start tw-space-x-3"
                        >
                          <Link
                            aria-label={`${__t('go-to')} ${__t(item.title_slug)}`}
                            prefetch={false}
                            className="tw-text-white/95"
                            href={item.path ? item.path : '/'}
                          >
                            {__t(item.title_slug)}
                          </Link>
                        </li>
                      ))
                    : null}
                </ul>
              </Collapse>
            </div>
          ))}
          {/* Contact */}
          <div className="tw-py-4 sm:tw-py-0">
            <a
              aria-label={__t('view-on-google-maps')}
              rel="noreferrer"
              target="_blank"
              href={metaInfo?.google_maps_link}
              className="tw-block tw-mb-4"
            >
              <div className="tw-text-white/95 tw-space-x-2">
                <i className="fas fa-map" />
                <span className="tw-font-bold">
                  {__t('view-on-google-maps')}
                </span>
                <i className="fas fa-chevron-right rtl:tw-rotate-180" />
              </div>
            </a>
            <ul className="tw-text-white/95 tw-space-y-2">
              {contactMenu.map((contact, i) => (
                <li
                  key={`contact-menu-${i}`}
                  className="[&_i]:ltr:tw-mr-2 [&_i]:rtl:tw-ml-2 [&_i]:tw-mt-1
                  [&_img]:ltr:tw-mr-2 [&_img]:rtl:tw-ml-2 [&_img]:tw-mt-1
                   [&_span]:tw-block tw-flex"
                >
                  <a
                    aria-label={removeHTMLTag(contact.text)}
                    href={contact?.link || '/'}
                    className={`tw-flex ${contact.link ? '' : 'tw-pointer-events-none'}`}
                  >
                    {contact?.custom_icon?.icon_white ? (
                      <Image
                        src={contact?.custom_icon?.icon_white?.url}
                        alt={contact?.text}
                        width={16}
                        height={16}
                      />
                    ) : (
                      <i className={`${contact.icon}`} />
                    )}
                    <span
                      dir={getDirFromStr(contact.text)}
                      dangerouslySetInnerHTML={{ __html: contact.text }}
                    />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default FooterMenu
