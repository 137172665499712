'use client'

import TouchRipple from '@/components/main/element/TouchRipple'
import { usePathname } from '@/navigation'
import useGlobal from '@/store/global'
import useMeta from '@/store/meta'
import { ListItemButton } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import { useTranslations } from 'next-intl'
import { useParams } from 'next/navigation'
import { useEffect, useState } from 'react'

const CCP_MobileExpandMenu = ({ mobileMenu }) => {
  //add language switcher to the mobile menu
  const locales = useMeta((state) => state?.metaInfo?.locales) || []
  const currentLocale = useParams().locale
  const fullMobileMenu = [
    {
      title: 'Language',
      locales: locales.map((locale) => ({
        label: locale.label,
        value: locale.value,
      })),
    },
    ...mobileMenu,
  ]

  const __t = useTranslations()
  const pathname = usePathname()
  const { isShowMobileMenu } = useGlobal()
  const [activeMenu, setActiveMenu] = useState(1)
  // Expand Menu
  const expandMenu = (index) => () => {
    index === activeMenu ? setActiveMenu(null) : setActiveMenu(index)
  }

  // Toggle dropdown
  const toggleMenu = () => {
    useGlobal.setState({ isShowMobileMenu: !isShowMobileMenu })

    const mainBody = document.getElementById('main-body')
    const mainFooter = document.getElementById('main-footer')
    if (mainBody && mainFooter) {
      mainBody.classList.toggle('max-sm:tw-hidden')
      mainFooter.classList.toggle('max-sm:tw-hidden')
    }
  }

  const clickLink = (newPath) => () => {
    //if going to the same path, close menu
    if (pathname.includes(newPath)) {
      toggleMenu()
    }
  }

  // watch for route change, then reset mobile menu
  useEffect(() => {
    useGlobal.setState({ isShowMobileMenu: false })
    const mainBody = document.getElementById('main-body')
    const mainFooter = document.getElementById('main-footer')
    if (mainBody && mainFooter) {
      mainBody.classList.remove('tw-hidden')
      mainFooter.classList.remove('tw-hidden')
    }
  }, [pathname])

  return (
    <>
      {/* Nav Icon */}
      <ListItemButton
        component="div"
        onClick={() => toggleMenu()}
        className="menu-button !tw-border-l md:!tw-border-l-0"
        aria-label="menu"
      >
        <i
          className={`far ${
            isShowMobileMenu ? 'fa-times' : 'fa-bars'
          } tw-text-3xl !tw-w-7 !tw-flex !tw-justify-center  !tw-items-center`}
        />
      </ListItemButton>
      {/* Dropdown */}
      {/* <div className="tw-fixed tw-w-full tw-h-full tw-bg-black/10 tw-backdrop-blur-2xl tw-top-0 tw-left-0 -tw-z-10 "></div> */}
      <nav
        className={`tw-bg-bgh-white  tw-w-full  tw-absolute tw-left-0 tw-top-full tw-pb-24  ${
          isShowMobileMenu ? 'tw-flex' : 'tw-hidden'
        }`}
      >
        <ul className="tw-flex tw-w-full tw-flex-col tw-border-bgh-blue-alpha ">
          {fullMobileMenu?.map((menu, i) => (
            <li key={`header-mobile-menu-${i}`}>
              {/* Group menu */}
              <h3
                onClick={expandMenu(i)}
                className={`${
                  activeMenu === i ? 'tw-bg-bgh-blue-alpha' : 'tw-bg-bgh-white'
                } tw-cursor-pointer tw-flex tw-items-center tw-py-4 hover:sm:tw-bg-bgh-blue-alpha  tw-duration-200 tw-border-b tw-border-bgh-blue-alpha tw-px-4 tw-space-x-2 tw-justify-between tw-group `}
              >
                {/* Main Menu */}

                <div
                  className={`tw-text-xl tw-duration-200 ${
                    i === activeMenu ? 'tw-text-bgh-blue' : ''
                  }`}
                >
                  {menu.title_slug ? __t(menu.title_slug) : menu.title}
                </div>

                {/* Expanded Icon */}
                {menu.items?.length && !menu.icon ? (
                  <i
                    className={`tw-relative ltr:tw-right-2.5 rtl:tw-left-2.5 far fa-chevron-right tw-text-sm  tw-duration-300 ${
                      i === activeMenu
                        ? 'tw-text-bgh-blue/50  tw-rotate-90 tw-font-bold'
                        : ''
                    }`}
                  />
                ) : null}

                {/* Language Switcher */}
                {menu.locales?.length && currentLocale ? (
                  <span className="tw-uppercase tw-bg-bgh-blue-alpha tw-p-1 tw-text-xs tw-w-8 tw-h-8 tw-flex tw-justify-center tw-items-center tw-rounded-lg">
                    {i === activeMenu ? (
                      <i className="far fa-globe tw-text-sm  tw-duration-300"></i>
                    ) : (
                      currentLocale
                    )}
                  </span>
                ) : null}
              </h3>
              {/* Sub menu */}
              {menu.items ? (
                <Collapse in={activeMenu === i}>
                  <ul
                    className={`tw-border-b tw-bg-white tw-border-bgh-blue-alpha tw-z-10 tw-py-2`}
                  >
                    {menu.items.map((item, j) =>
                      item.icon ? (
                        <li
                          className="!tw-p-0 !tw-m-0 tw-flex tw-flex-col"
                          key={`header-mobile-item-${i}-${j}`}
                          onClick={clickLink(item.path)}
                        >
                          <TouchRipple
                            prefetch={false}
                            href={item.path}
                            className="hover:tw-bg-bgh-blue-alpha/50 tw-flex tw-items-center tw-space-x-3 tw-px-4 tw-py-2 tw-duration-200 tw-group tw-w-full"
                          >
                            {item.icon ? (
                              <i
                                className={`tw-w-8 tw-h-8 tw-rounded-lg !tw-flex !tw-items-center !tw-justify-center tw-bg-bgh-blue-alpha tw-text-bgh-blue far fa-${item.icon}`}
                              />
                            ) : null}
                            <div className="text-button">
                              {__t(item.title_slug)}
                            </div>
                          </TouchRipple>
                          {/* Subitem */}
                          {item.subitems?.length ? (
                            <ul className="tw-grid tw-grid-cols-2  tw-pl-[55px] tw-pr-4 tw-pb-2">
                              {item.subitems.map((subitem, k) => (
                                <li
                                  key={`header-mobile-subitem-${i}-${j}-${k}`}
                                  className="tw-flex"
                                  onClick={clickLink}
                                >
                                  <TouchRipple
                                    prefetch={false}
                                    href={subitem.path}
                                    className="tw-text-bgh-gray-primary hover:tw-text-bgh-gray-primary-hover tw-duration-200 tw-font-bold tw-w-full tw-p-1.5 tw-rounded"
                                  >
                                    {__t(subitem.title_slug)}
                                  </TouchRipple>
                                </li>
                              ))}
                            </ul>
                          ) : null}
                        </li>
                      ) : null
                    )}
                  </ul>
                </Collapse>
              ) : null}

              {/* Language Sub menu */}
              {menu.locales?.length ? (
                <Collapse in={activeMenu === i}>
                  <ul className="tw-border-b tw-bg-white tw-border-bgh-blue-alpha tw-z-10 tw-py-2 tw-grid tw-grid-cols-2">
                    {menu.locales.map((locale, k) => (
                      <li
                        className={`!tw-p-0 !tw-m-0 tw-flex tw-flex-col ${
                          currentLocale === locale.value && 'tw-opacity-90'
                        }`}
                        key={`header-mobile-language-item-${i}-${k}`}
                      >
                        <TouchRipple
                          prefetch={false}
                          isATag
                          href={`/${locale.value}${pathname}`}
                          className="hover:tw-bg-bgh-blue-alpha/50 tw-flex tw-items-center tw-space-x-3 tw-px-4 tw-py-2 tw-duration-200 tw-group tw-w-full"
                        >
                          {currentLocale === locale.value ? (
                            <i className="tw-w-8 tw-h-8 tw-rounded-lg !tw-flex !tw-items-center !tw-justify-center tw-bg-bgh-blue-alpha tw-text-bgh-blue fas fa-check-circle" />
                          ) : (
                            <span className="tw-w-8 tw-h-8 tw-rounded-lg !tw-flex !tw-items-center !tw-justify-center tw-bg-bgh-blue-alpha tw-text-bgh-blue tw-uppercase tw-text-[10px] tw-font-bold">
                              {locale.value}
                            </span>
                          )}

                          <div className="text-button">{locale.label}</div>
                        </TouchRipple>
                      </li>
                    ))}
                  </ul>
                </Collapse>
              ) : null}
            </li>
          ))}
        </ul>
      </nav>
    </>
  )
}

export default CCP_MobileExpandMenu
