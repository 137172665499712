'use client'
import useGlobal from '@/store/global'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

export default function CCP_DialogConfirmation() {
  const { confirmationDialog } = useGlobal()
  const handleClose = () => {
    useGlobal.setState({
      confirmationDialog: null,
    })
  }

  return (
    <>
      <Dialog
        open={confirmationDialog}
        onClose={handleClose}
        aria-labelledby="confirmation-dialog"
      >
        <DialogTitle id="confirmation-dialog">
          {confirmationDialog?.title}
        </DialogTitle>
        <DialogContent>
          <div
            dangerouslySetInnerHTML={{
              __html: confirmationDialog?.description,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={
              confirmationDialog?.cancelButtonAction
                ? confirmationDialog?.cancelButtonAction
                : handleClose
            }
          >
            {confirmationDialog?.cancelButtonLabel}
          </Button>
          <Button onClick={confirmationDialog?.confirmButtonAction} autoFocus>
            {confirmationDialog?.confirmButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
