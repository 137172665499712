'use client'
import { Link } from '@/navigation'
import useMeta from '@/store/meta'
import { Skeleton } from '@mui/material'
import { useTranslations } from 'next-intl'
import Image from 'next/image'

export default function CCP_FooterSocial() {
  const __t = useTranslations()
  const { metaInfo } = useMeta()
  let socials = metaInfo?.social_channels?.slice(0, 5)
  const customSocials = metaInfo?.override_social_channels
  if (customSocials) {
    socials = customSocials
  }

  return (
    <div className="2xl:ltr:tw-pl-12 2xl:rtl:tw-pr-12 sm:ltr:tw-pl-8 sm:rtl:tw-pr-8 tw-flex tw-flex-col tw-justify-center">
      <span className="tw-text-bgh-dark tw-font-bold tw-capitalize tw-text-xl">
        {__t('follow-us')}
      </span>
      <div className="tw-flex tw-items-center tw-space-x-4 tw-justify-between tw-mt-4 tw-h-full">
        {socials
          ? socials?.map((social, i) => (
              <Link
                aria-label="social-link"
                prefetch={false}
                href={social?.url}
                target="_blank"
                rel="noopenner"
                key={`social-link-footer-${i}`}
              >
                {social?.custom_icon?.icon_color ? (
                  <Image
                    src={social?.custom_icon?.icon_color?.url}
                    alt={social?.channel_name}
                    width={40}
                    height={40}
                  />
                ) : (
                  <i
                    style={{ color: social?.fa_icon.color }}
                    className={`fab fa-${social?.fa_icon.icon} tw-text-3xl  hover:tw-scale-110  tw-duration-200`}
                  />
                )}
              </Link>
            ))
          : [0, 0, 0, 0].map((ske, i) => (
              <Skeleton
                key={`social-skeleton-${i}`}
                variant="rounded"
                width={40}
                height={40}
              />
            ))}
      </div>
    </div>
  )
}
